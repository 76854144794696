import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="">
                <i className="bx bx-home-circle"></i>

                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/students" className="">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Students")}</span>
              </Link>
            </li>
            <li>
              <Link to="/courses" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Courses")}</span>
              </Link>
            </li>
            <li>
              <Link to="/featured-courses" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Dynamic Section")}</span>
              </Link>
            </li>
            <li>
              <Link to="/bundle-courses" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Bundle Courses")}</span>
              </Link>
            </li>
            <li>
              <Link to="/seo-keywords" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("SEO Keywords")}</span>
              </Link>
            </li>

            <li>
              <Link to="/quiz" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Quiz")}</span>
              </Link>
            </li>
            <li>
              <Link to="/seminar" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Seminar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/seminarStudent" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Seminar Student")}</span>
              </Link>
            </li>

            <li>
              <Link to="/affiliateList" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Affiliate")}</span>
              </Link>
            </li>

            <li>
              <Link to="/support" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Support")}</span>
              </Link>
            </li>

            <li>
              <Link to="/orderList" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Order List")}</span>
              </Link>
            </li>
            <li>
              <Link to="/certificate" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Certificate")}</span>
              </Link>
            </li>
            <li>
              <Link to="/enrolled-courses" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Manual Enrollment")}</span>
              </Link>
            </li>
            <li>
              <Link to="/manual-enroll-payment" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Manual Payment")}</span>
              </Link>
            </li>
            <li>
              <Link to="/category" className="">
                <i className="bx bx-grid-alt"></i>
                <span>{props.t("Course Category")}</span>
              </Link>
            </li>
            <li>
              <Link to="/book" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Books")}</span>
              </Link>
            </li>

            <li>
              <Link to="/subscribers" className="">
                <i className="bx bx-book"></i>
                <span>{props.t("Subscribers")}</span>
              </Link>
            </li>

            <li>
              <Link to="/manual-payment" className="">
                <i className="bx bx-money"></i>
                <span>{props.t("Settings")}</span>
              </Link>
            </li>
            <li>
              <Link to="/scholarship-student" className="">
                <i className="bx bx-money"></i>
                <span>{props.t("Scholarship")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admins" className="">
                <i className="bx bx-user-plus"></i>
                <span>{props.t("Admin")}</span>
              </Link>
            </li>
            <li>
              <Link to="/instructors" className="">
                <i className="bx bx-user"></i>
                <span>{props.t("Instructors")}</span>
              </Link>
            </li>
            <li>
              <Link to="/coupons" className="">
                <i className="bx bxs-coupon"></i>
                <span>{props.t("Coupons")}</span>
              </Link>
            </li>
            <li>
              <Link to="/notice" className="">
                <i className="bx bxs-spreadsheet"></i>
                <span>{props.t("Notice")}</span>
              </Link>
            </li>
            <li>
              <Link to="/blogs" className="">
                <i className="bx bx-detail"></i>
                <span>{props.t("Blogs")}</span>
              </Link>
            </li>
            <li>
              <Link to="/dynamic-data" className="">
                <i className="bx bx-detail"></i>
                <span>{props.t("Dynamic Data")}</span>
              </Link>
            </li>
          </ul>
        </div>
        e
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
