import React, { useState, useEffect } from "react"
import { Form, FormGroup, Label, Input, Button } from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"

const SEOKeywords = () => {

    const [courseList, setCourseList] = useState([])
    const [courseId, setCourseId] = useState("")
    const [seoData, setSeoData] = useState([])
    const [SEOTitle, setSEOTitle] = useState("")
    const [SEOMetaDescription, setSEOMetaDescription] = useState("")
    const [seoKeywords, setSeoKeywords] = useState("")

    const getAllCourse = () => {
        fetch(`${process.env.REACT_APP_URL}/api/course/get`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(res => res.json())
            .then(data => {
                console.log({ data }, "course list");
                setCourseList(data?.message)
            })
            .catch(err => console.log(err?.response?.data))
    }

    useEffect(() => {
        getAllCourse()
    }, [])

    useEffect(() => {
        // https://ourprofessors.com/api/order/course/${courseId}
        // ${process.env.REACT_APP_URL}/api/order/course/${courseId}
        if (courseId) {
            fetch(`${process.env.REACT_APP_URL}/new/api/course/seo/key-words/${courseId}`, {
                method: "GET",

                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
                .then(res => res?.json())
                .then(data => {

                    console.log({ data }, "course wise data")
                    if (data?.success) {
                        setSeoData(data?.data)
                        const stringList = data?.data?.SEOKeyWords || [];
                        const commaSeparatedString = stringList.join(', ');
                        setSeoKeywords(commaSeparatedString)
                        // toastr.success(data?.message, "Order")
                        console.log("courseId", data)
                    } else {
                        // toastr.error(data?.message, "Order")
                    }
                })
                .catch(err => { console.log(err) })
        }
    }, [courseId])


    const handleSave = () => {

        if (!courseId) {
            toastr.error("Please select a course", "SEO");
            return
        }

        if (seoKeywords?.length == 0 || SEOTitle?.length == 0 || SEOMetaDescription.length == 0) {
            toastr.error("Please fill all the fields", "SEO");
            return
        }

        if (courseId && seoKeywords) {

            fetch(`${process.env.REACT_APP_URL}/new/api/course/seo/key-words/${courseId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({ SEOKeyWords: seoKeywords, SEOTitle, SEOMetaDescription })
            })
                .then(res => res?.json())
                .then(data => {
                    console.log(data, "response");

                    setSeoData(data?.data)
                    setSEOTitle(data?.data?.SEOTitle);
                    setSEOMetaDescription(data?.data?.SEOMetaDescription);
                    const stringList = data?.data?.SEOKeyWords || [];
                    const commaSeparatedString = stringList.join(', ');
                    setSeoKeywords(commaSeparatedString)
                    toastr.success(data?.message, "SEO")
                    console.log("courseId", data)

                })
                .catch(err => { console.log(err); toastr.error(err?.response?.data?.message, "SEO") })
        }
    }
    return (
        <div className="orderDiv">
            <h3>SEO Keywords</h3>
            <Form>
                <FormGroup>
                    <Label for="SelectCourseTitle">Course Title</Label>
                    <Input
                        name="courseTitle"
                        type="select"
                        onChange={e => setCourseId(e.target.value)}
                    >
                        {courseList?.map(course => {
                            return (
                                <>
                                    <option value="" hidden>
                                        Select
                                    </option>
                                    <option key={course?._id} value={course._id}>{course.title}</option>
                                </>
                            )
                        })}
                    </Input>
                </FormGroup>
            </Form>
            {seoData ? (
                <div style={{ marginTop: "15px" }}>
                    <Label for="SelectCourseTitle">Seo Title</Label>
                    <Input
                        onChange={e => {
                            setSEOTitle(e.target.value)
                        }}
                        value={SEOTitle}
                        placeholder="Enter seo title here"
                    />
                    <Label style={{ marginTop: "15px" }} for="SelectCourseDescription">Seo Description</Label>
                    <Input
                        type="textarea"
                        onChange={e => {
                            setSEOMetaDescription(e.target.value)
                        }}
                        value={SEOMetaDescription}
                        maxLength="620"
                        rows="3"
                        placeholder="Enter seo description here"
                    />
                    <Label style={{ marginTop: "15px" }} for="SelectCourseTitle">Seo Keywords</Label>
                    <Input
                        type="textarea"
                        onChange={e => {
                            setSeoKeywords(e.target.value)
                        }}
                        value={seoKeywords}
                        maxLength="620"
                        rows="3"
                        placeholder="Enter seo keywords here"
                    />
                    <button className="btn btn-primary mt-2" onClick={handleSave}>Save</button>
                </div>
            ) : null}
        </div>
    )
}

export default SEOKeywords
