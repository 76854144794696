import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { map } from "lodash"

import {
    Col,
    Container,
    Row,
    Table,
    Button,
    FormGroup,
    Label,
    Input,
} from "reactstrap"
import "../Courses/course.scss"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Image
import { useHistory } from "react-router-dom"
import ScholarShipStudentList from "./ScholarShipDetaila"

const ScholarShipStudents = () => {
    const [subscriberList, setSubscriberList] = useState([])
    const [status, setStatus] = useState(false)

    const [sType, setSType] = useState(1)

    const [pendingScholarships, setPendingScholarships] = useState([])
    const [approvedScholarships, setApprovedScholarships] = useState([])
    const [rejectedScholarships, setRejectedScholarships] = useState([])

    const [showScholarships, setShowScholarships] = useState(null)

    console.log({ sType })
    useEffect(() => {
        if (subscriberList?.length > 0) {
            console.log("under length greter than 0")
            if (parseInt(sType) === 1) {
                console.log("P")
                setShowScholarships(pendingScholarships)
            } else if (parseInt(sType) === 2) {
                console.log("A")
                setShowScholarships(approvedScholarships)
            } else if (parseInt(sType) === 3) {
                console.log("R")
                setShowScholarships(rejectedScholarships)
            }
        } else {
            setShowScholarships([])
        }
    }, [sType, subscriberList])

    console.log({ showScholarships })

    const history = useHistory()

    // Get all courses
    useEffect(() => {
        fetch(`https://www.ourprofessors.com/new/api/scholarship/get-all`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(res => res.json())
            .then(data => {
                setSubscriberList(data.data)
                console.log({ data }, "subscriber")

                let approvedS = []
                let pendingS = []
                let rejectedS = []

                data?.data?.map(d => {
                    console.log("inside map")
                    if (d?.status == "ACCEPTED") {
                        approvedS.push(d)
                    } else if (d?.status == "REJECTED") {
                        rejectedS.push(d)
                    } else {
                        pendingS.push(d)
                    }
                })

                setApprovedScholarships(approvedS)
                setPendingScholarships(pendingS)
                setRejectedScholarships(rejectedS)
                setShowScholarships(pendingS)
            })
            .catch(err => console.log(err))
    }, [status])

    // delete specific course
    console.log({
        pendingScholarships,
        approvedScholarships,
        rejectedScholarships,
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Our Professor</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs
                        title="Scholarship"
                        breadcrumbItem="Scholarship student List"
                    />
                    {/* <Button
            onClick={() => history.push(`/subscriber/add/new`)}
            color="primary"
            className="btn btn-primary "
          >
            Add New Subscriber
          </Button> */}
                    <Row>
                        <FormGroup>
                            <Label className="mt-3" for="Seminar Name">
                                Transaction Method
                            </Label>
                            <Input
                                name="zone"
                                type="select"
                                // onChange={e => setZone(e.target.value)}
                                onChange={e => {
                                    setSType(e.target.value)
                                }}
                            >
                                <option value={1}>Pending</option>
                                <option value={2}>Approved</option>
                                <option value={3}>Rejected</option>
                            </Input>
                        </FormGroup>
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone No</th>
                                                <th scope="col">Course</th>
                                                <th scope="col">Present Condition</th>
                                                <th scope="col">Reason</th>
                                                {sType != 1 ? <th scope="col">Status</th> : null}
                                                <th scope="col">Details</th>]
                                                {/* <th scope="col">Time</th>
                        <th scope="col">Zone</th>
                        <th scope="col">Type</th> */}
                                                {/* <th scope="col">Option</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* {map(subscriberList, (subscriber, index) => (
                                                <ScholarShipStudentList

                                                    data={subscriber}
                                                    key={index}
                                                />
                                            ))} */}

                                            {showScholarships?.map((subscriber, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span className="text-dark">
                                                            {subscriber?.name}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>{subscriber?.email}</span>
                                                    </td>
                                                    <td>
                                                        <span>{subscriber?.phoneNumber}</span>
                                                    </td>
                                                    <td>
                                                        <span>{subscriber?.courseName}</span>
                                                    </td>
                                                    <td>
                                                        <span>{subscriber?.presentCondition}</span>
                                                    </td>

                                                    <td>
                                                        <span
                                                            style={{
                                                                width: "400px",
                                                                maxWidth: "400px",
                                                                whiteSpace: "pre-wrap",
                                                            }}
                                                        >
                                                            {subscriber?.reason}
                                                        </span>
                                                    </td>
                                                    {sType != 1 ? (
                                                        <td>
                                                            <button
                                                                style={{ marginRight: "10px" }}
                                                                className={`btn ${subscriber?.status === "REJECTED" ? "btn-danger" : "btn-primary"} w-md`}
                                                            >
                                                                {subscriber?.status === "ACCEPTED" ||
                                                                    subscriber?.status === "REJECTED"
                                                                    ? subscriber?.status
                                                                    : "PENDING"}
                                                            </button>
                                                        </td>
                                                    ) : null}

                                                    <td>
                                                        <button
                                                            style={{ marginRight: "10px" }}
                                                            onClick={() => {
                                                                history.push(
                                                                    `/scholarship-details/${subscriber?._id}`
                                                                )
                                                            }}
                                                            className="btn btn-primary w-md"
                                                        >
                                                            View
                                                        </button>
                                                    </td>

                                                    {/* <td><button onClick={() =>
                                                        window.confirm(
                                                            "Do you want to reject this scholarship?"
                                                        )
                                                            ? handleDelete(support?._id)
                                                            : null
                                                    }
                                                        className="btn btn-danger w-md">Reject</button></td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ScholarShipStudents)
